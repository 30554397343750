import shouldForwardProp from "@styled-system/should-forward-prop"
import React from "react"
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system"

import Icon from "../Icon/Icon"
import styled from "@emotion/styled"

export type StyleProps = LayoutProps & SpaceProps & ColorProps & TypographyProps

const Label = styled("label", {
  shouldForwardProp,
})<StyleProps>`
  cursor: pointer;
  display: inline-flex;
  justify-content: flex-start;
  vertical-align: top;
  ${(props) => props.theme.colors.grey800};
  ${layout};
  ${space};
  ${color};
  ${typography};
`

const Input = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  &:not(:checked) ~ span > svg {
    display: none;
  }
`

const Tick = styled.span`
  position: relative;
  top: 3px;
  display: inline-flex;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  background: #fff;
  border: 2px solid ${(props) => props.theme.colors.grey800};
  justify-content: center;
  align-items: center;
  user-select: none;
`

const LabelText = styled.div`
  margin-left: 0.75rem;
`

export type Props = StyleProps &
  Pick<
    React.InputHTMLAttributes<HTMLInputElement>,
    "checked" | "onChange" | "name" | "id"
  > & {
    children?: React.ReactNode | string
  }

const Checkbox = ({
  children,
  color = "black",
  checked,
  onChange,
  name,
  id,
  ...props
}: Props) => {
  return (
    <Label color={color as any} {...props}>
      <Input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        name={name}
        id={id}
      />
      <Tick aria-hidden>
        <Icon size="10px" name="checkmark" />
      </Tick>
      {children && <LabelText>{children}</LabelText>}
    </Label>
  )
}

export default Checkbox
