import Link, { AppLink } from "@ui/Link/Link"
import React, { useState, useEffect } from "react"
import isRelativeUrl from "is-relative-url"

const isPdf = (url: string) => url.endsWith(".pdf")

const Hyperlink = ({ href = "", ...props }) => {
  const [url, setUrl] = useState(href)

  useEffect(() => {
    if (href && href.startsWith(window.location.origin)) {
      setUrl(href.replace(window.location.origin, ""))
    }
  }, [href])

  if (!url) {
    return null
  }

  if (isRelativeUrl(url) && !isPdf(url)) {
    return <AppLink to={url} {...props} />
  }

  // eslint-disable-next-line
  return (
    <Link href={url} target="_blank" rel="noopener noreferrer" {...props} />
  )
}

export default Hyperlink
