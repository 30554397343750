import React from "react"
import Checkbox, { Props as CheckboxProps } from "@ui/Checkbox/Checkbox"
import { useSelector, useDispatch } from "react-redux"
import { isCheckboxCheckedSelector, checkCheckbox } from "@ducks/checkboxSlice"
import { RootState } from "@ducks/rootReducer"

type ModuleCheckboxProps = CheckboxProps & {
  id: string
  module: string
  step: string
}

const ModuleCheckbox = ({
  children,
  display = "flex",
  my = "xs",
  id,
  module,
  step,
  ...rest
}: ModuleCheckboxProps) => {
  const checked = useSelector((state: RootState) =>
    isCheckboxCheckedSelector(state, { id, module, step })
  )
  const dispatch = useDispatch()

  return (
    <Checkbox
      display={display}
      my={my}
      {...rest}
      id={id}
      checked={checked}
      onChange={(event) =>
        dispatch(
          checkCheckbox({
            module,
            id,
            step,
            checked: event.target.checked,
          })
        )
      }
    >
      {children}
    </Checkbox>
  )
}

export default ModuleCheckbox
