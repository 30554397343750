import React from "react"
import styled from "@emotion/styled"
import { space, SpaceProps, layout, LayoutProps } from "styled-system"

const DownloadIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.406 35.356">
    <path
      id="Path_22"
      data-name="Path 22"
      d="M478.4,251.263v-.436q0-8.716,0-17.43a1.955,1.955,0,0,1,1.671-2.045,1.932,1.932,0,0,1,2.2,1.587,4.6,4.6,0,0,1,.039.666q0,8.585,0,17.17v.582c.158-.151.251-.236.34-.326,1.524-1.524,3.043-3.054,4.576-4.57a1.941,1.941,0,1,1,2.742,2.749q-4.108,4.118-8.226,8.225a1.934,1.934,0,0,1-2.85-.04q-2.738-2.727-5.467-5.465-1.3-1.3-2.6-2.6a1.964,1.964,0,1,1,2.775-2.757q2.223,2.217,4.44,4.442a2.765,2.765,0,0,1,.244.327Z"
      transform="translate(-462.633 -231.326)"
      fill="#fff"
    />
    <path
      id="Path_23"
      data-name="Path 23"
      d="M463.009,272.821h28.721v-.443c0-1.908-.006-3.815,0-5.723a1.675,1.675,0,0,1,2.348-1.628,1.519,1.519,0,0,1,.972,1.423q.031,4.031,0,8.063a1.681,1.681,0,0,1-1.762,1.681c-1.412.008-2.824,0-4.236,0H461.669a1.746,1.746,0,0,1-2.006-1.994c0-2.551,0-5.1,0-7.655a1.645,1.645,0,0,1,1.207-1.6,1.6,1.6,0,0,1,1.84.7A2.306,2.306,0,0,1,463,266.7c.027,1.895.013,3.79.013,5.685Z"
      transform="translate(-459.663 -240.841)"
      fill="#fff"
    />
  </svg>
)

const InfoIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43.092 43.782">
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_395"
          data-name="Rectangle 395"
          width="43.092"
          height="43.782"
          fill="#053b41"
        />
      </clipPath>
    </defs>
    <g
      id="Group_494"
      data-name="Group 494"
      transform="translate(-142.364 -336.11)"
    >
      <g
        id="Group_489"
        data-name="Group 489"
        transform="translate(142.364 336.11)"
        clipPath="url(#clip-path)"
      >
        <path
          id="Path_935"
          data-name="Path 935"
          d="M163.489,379.627h-2.957c-.024-.545-.047-1.036-.077-1.677-.861,0-1.724.008-2.587,0-1.425-.017-2.222-.739-2.2-1.985.019-1.209.79-1.888,2.188-1.908.84-.013,1.679,0,2.578,0v-3.412H154.7c-.376,0-.754.013-1.128-.006a1.944,1.944,0,0,1-1.957-1.876,1.9,1.9,0,0,1,1.841-1.984c1-.066,2-.024,3.008-.026,1.307,0,2.613,0,3.973,0v-3.841c-.855,0-1.69.011-2.525,0a2.013,2.013,0,0,1-2.252-2.079c.024-1.181.818-1.859,2.211-1.878.837-.009,1.675,0,2.564,0v-3.461h-3.3c-1.128,0-2.256.011-3.384,0-1.318-.019-2.132-.735-2.14-1.85a1.915,1.915,0,0,1,2.119-1.982c1.912-.017,3.822,0,5.734-.008.306,0,.613-.026.968-.041v-3.854c-.946,0-1.846.011-2.747,0a1.939,1.939,0,1,1-.041-3.866c.438-.021.878-.006,1.316-.006s.87,0,1.457,0c0-1.429.028-2.79-.039-4.146-.008-.156-.553-.406-.85-.41-3.226-.03-6.454-.019-9.683-.019-1.222,0-2.446.045-3.664-.015-.761-.038-.957.229-.951.963.028,4.913.015,9.825.015,14.738v24.639h-2.882v-.993q0-19.194,0-38.392c0-2.651,1.177-3.82,3.843-3.822q6.768-.006,13.537,0c2.542,0,3.745,1.2,3.745,3.721v39.493Z"
          transform="translate(-142.364 -335.847)"
          fill="#053b41"
        />
        <path
          id="Path_936"
          data-name="Path 936"
          d="M169.5,350.408c-.416-1.237-.8-2.416-1.211-3.585-.391-1.107-.513-2.658-1.307-3.183-.854-.564-2.35-.164-3.567-.182a.931.931,0,0,0-.37.015c-.156.07-.367.167-.412.3-.752,2.2-1.48,4.4-2.209,6.6,1.54.231,2.931.7,4.356.071a1.453,1.453,0,0,1,1.1.039c1.22.624,2.361-.006,3.617-.075m2.023,29.484h-2.068v-26.97c0-1.137-.434-1.487-1.468-1.1-.243.092-.425.442-.549.713a1.7,1.7,0,0,0-.017.651v26.7h-1.412v-28.07a5.828,5.828,0,0,0-.944-.167c-.763-.008-1.222.16-1.216,1.162.047,8.635.026,17.272.026,25.908v1.168h-1.384V353.17c0-1.145-.461-1.583-1.585-1.406-.231.038-.468.355-.605.6-.092.165-.019.427-.019.647v26.88h-2.111v-1.138c0-8.729.028-17.458-.023-26.19a16.3,16.3,0,0,1,1.085-5.386q1.481-4.346,3.061-8.658a4.926,4.926,0,0,1,1.064-1.732,2.47,2.47,0,0,1,3.862.632,8.376,8.376,0,0,1,.752,1.811q1.7,5.26,3.358,10.532a5.39,5.39,0,0,1,.224,1.57q.02,13.94.008,27.878c0,.212-.023.423-.038.682"
          transform="translate(-128.472 -336.11)"
          fill="#053b41"
        />
      </g>
    </g>
  </svg>
)

const Block = styled.div<LayoutProps & SpaceProps>`
  appearance: none;

  border: 0;
  min-height: 7rem;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  text-align: left;

  &[data-type="info"] {
    background: ${({ theme }) => theme.colors.snow};
    color: ${({ theme }) => theme.colors.grey900};
  }

  &[data-type="download"] {
    background: ${({ theme }) => theme.colors.sherpa};
    color: #fff;
  }

  ${layout}
  ${space}
`

const Icon = styled.div`
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  margin-right: 2rem;
`

export type BlockLinkProps = { type: "info" | "download" } & LayoutProps &
  SpaceProps &
  React.HTMLAttributes<HTMLDivElement>

const BlockLink = ({
  children,
  type,
  maxWidth = "29rem",
  ...props
}: BlockLinkProps) => {
  return (
    <Block data-type={type} maxWidth={maxWidth} {...props}>
      <Icon>{type === "info" ? <InfoIcon /> : <DownloadIcon />}</Icon>
      {children}
    </Block>
  )
}

export default BlockLink
