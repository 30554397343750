import CenterLayout from "@components/centerlayout"
import Hyperlink from "@components/hyperlink"
import Layout from "@components/layout"
import {
  AsideLayout,
  Card,
  ChecklistCard,
  ModuleBackground,
  Pagination,
  PaginationLink,
  Step,
} from "@components/module"
import ModuleCheckboxlist from "@components/modulecheckboxlist"
import ModuleNav from "@components/modulenav/modulenav"
import DividerLine from "@components/DividerLine"
import SEO from "@components/seo"
import { MDXProvider } from "@mdx-js/react"
import Box from "@ui/Box"
import Heading from "@ui/Heading/Heading"
import HorizontalRule from "@ui/HorizontalRule/HorizontalRule"
import Icon from "@ui/Icon/Icon"
import Link from "@ui/Link/Link"
import BlockLink from "@ui/BlockLink/BlockLink"
import List, { OrderedList } from "@ui/List/List"
import ListItem from "@ui/List/ListItem"
import styled from "@emotion/styled"
import Text from "@ui/Text/Text"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import module1BgSrc from "../images/module_backgrounds/module_1.svg"
import module2BgSrc from "../images/module_backgrounds/module_2.svg"
import module3BgSrc from "../images/module_backgrounds/module_3.svg"
import module4BgSrc from "../images/module_backgrounds/module_4.svg"
import module5BgSrc from "../images/module_backgrounds/module_5.svg"
import module6BgSrc from "../images/module_backgrounds/module_6.svg"
import defaultIllustrationSrc from "../images/placeholder_illustration.svg"
import ModuleCheckbox from "@components/modulecheckbox"

const getModuleSrc = (id: string) => {
  switch (id) {
    case "preparation":
      return module1BgSrc
    case "vision":
      return module2BgSrc
    case "context-analysis":
      return module3BgSrc
    case "future-analysis":
      return module4BgSrc
    case "strategic-plan":
      return module5BgSrc
    case "operational-plan":
      return module6BgSrc
    default:
      return defaultIllustrationSrc
  }
}

const Uppercase = styled(Text)`
  text-transform: uppercase;
`

Uppercase.defaultProps = {
  as: "span",
  color: "grey900",
  size: "xs",
  fontWeight: "bold",
}

const PaginationLabel = styled(Text)<{ justifyContent?: "flex-end" }>`
  display: inline-flex;
  justify-content: ${(props) => props.justifyContent};
  align-items: flex-start;
`

PaginationLabel.defaultProps = {
  as: "span",
  color: "grey900",
  size: "sm",
}

interface Props {
  data: {
    mdx: {
      body: string
      frontmatter: {
        kind: string
        id: string
        title: string
      }
    }
    currentModule: {
      frontmatter: {
        id: string
        nbr: string
        title: string
      }
    }
    checkboxes: {
      edges: {
        node: {
          body: string
          frontmatter: {
            kind: "checkbox"
            module: string
            id: string
            step: string
            order: number
          }
        }
      }[]
    }
    steps: {
      edges: {
        node: {
          frontmatter: {
            kind: "step"
            id: string
            title: string
          }
        }
      }[]
    }
  }
  pageContext: {
    module: string
    parent?: string
    step: string
    stepNumber?: string
    path: string
    previous?: {
      title: string
      path: string
    }
    next?: {
      title: string
      path: string
    }
  }
}

const ModuleStep = ({ data, pageContext }: Props) => (
  <MDXProvider
    components={{
      h1: ({ children, ...props }) => (
        <Heading
          fontWeight={600}
          size="3xl"
          mt="xl"
          mb="md"
          as="h1"
          lineHeight="shorter"
          {...props}
        >
          {pageContext.stepNumber && (
            <>
              Step {pageContext.stepNumber}: <br />
            </>
          )}
          {children}
        </Heading>
      ),
      h2: (props) => (
        <Heading
          fontWeight={600}
          mt="xl"
          mb="md"
          size="2xl"
          as="h2"
          lineHeight="shorter"
          {...props}
        />
      ),
      h3: (props) => (
        <Heading
          fontWeight={600}
          mt="xl"
          mb="md"
          size="xl"
          as="h3"
          lineHeight="short"
          {...props}
        />
      ),
      h4: (props) => (
        <Heading
          fontWeight={600}
          mt="lg"
          mb="md"
          size="lg"
          as="h4"
          lineHeight="short"
          {...props}
        />
      ),
      h5: (props) => (
        <Heading
          fontWeight={600}
          mt="md"
          mb="md"
          size="md"
          as="h5"
          {...props}
        />
      ),
      p: (props) => <Text size="md" mt="md" mb="lg" {...props} />,
      ul: (props) => <List my="xl" {...props} />,
      ol: (props) => <OrderedList my="xl" {...props} />,
      li: (props) => <ListItem my="3xs" {...props} />,
      hr: (props) => <HorizontalRule {...props} />,
      a: (props) => <Hyperlink {...props} />,
      Box,
      Card,
      ChecklistCard,
      DividerLine,
      BlockLink,
      Text,
      Link,
      ModuleCheckbox,
      Checklist: (props) => (
        <ModuleCheckboxlist
          {...props}
          module={pageContext.module}
          step={pageContext.step}
          checkboxData={data.checkboxes?.edges?.map(({ node }) => ({
            body: node.body,
            ...node.frontmatter,
          }))}
          stepData={data.steps?.edges?.map(({ node }) => ({
            ...node.frontmatter,
          }))}
        />
      ),
    }}
  >
    <Layout>
      <SEO
        title={`${data.mdx.frontmatter.title} | Module ${data.currentModule.frontmatter.nbr}`}
      />
      <ModuleBackground
        loading="eager"
        src={getModuleSrc(pageContext.module)}
      />
      <CenterLayout my="3xl">
        <AsideLayout>
          <ModuleNav module={pageContext.module} />
          <Step>
            <MDXRenderer>{data.mdx.body}</MDXRenderer>
            <Pagination>
              {pageContext.previous && (
                <PaginationLink align="left" to={pageContext.previous.path}>
                  <Uppercase>Previous</Uppercase>
                  <PaginationLabel ml="-24px">
                    <Icon name="chevronLeft" size="24px" />
                    {pageContext.previous.title}
                  </PaginationLabel>
                </PaginationLink>
              )}
              {pageContext.next && (
                <PaginationLink align="right" to={pageContext.next.path}>
                  <Uppercase textAlign="right">Next</Uppercase>
                  <PaginationLabel
                    mr="-24px"
                    justifyContent="flex-end"
                    textAlign="right"
                  >
                    {pageContext.next.title}{" "}
                    <Icon name="chevronRight" size="24px" />
                  </PaginationLabel>
                </PaginationLink>
              )}
            </Pagination>
          </Step>
        </AsideLayout>
      </CenterLayout>
    </Layout>
  </MDXProvider>
)

export const pageQuery = graphql`
  query ($module: String!, $step: String!) {
    mdx(
      frontmatter: {
        id: { eq: $step }
        module: { eq: $module }
        kind: { eq: "step" }
      }
    ) {
      body
      frontmatter {
        kind
        module
        title
        order
      }
    }

    currentModule: mdx(
      frontmatter: { kind: { eq: "module" }, id: { eq: $module } }
    ) {
      frontmatter {
        id
        nbr
        title
      }
    }

    checkboxes: allMdx(
      sort: { order: ASC, fields: [frontmatter___order] }
      filter: {
        frontmatter: { kind: { eq: "checkbox" }, module: { eq: $module } }
      }
    ) {
      edges {
        node {
          body
          frontmatter {
            id
            kind
            step
            order
            module
          }
        }
      }
    }

    steps: allMdx(
      sort: { order: ASC, fields: [frontmatter___order] }
      filter: { frontmatter: { kind: { eq: "step" }, module: { eq: $module } } }
    ) {
      edges {
        node {
          frontmatter {
            id
            kind
            title
          }
        }
      }
    }
  }
`

export default ModuleStep
