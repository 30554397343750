import { rem } from "polished"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import Box from "@ui/Box"

export const ModuleBackground = styled.img<{ loading: "eager" }>`
  position: absolute !important;
  top: -1px;
  left: 0;
  right: 0;
  z-index: -1;
  object-fit: cover;
  object-position: 54% center;
  width: 100%;
  height: ${rem("400px")};
  background-color: #fff;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 85%);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 1);
`

export const AsideLayout = styled.div`
  display: grid;
  align-items: flex-start;
  grid-template-rows: auto auto;
  grid-gap: ${(props) => props.theme.space.xl};

  @media screen and (min-width: 45em) {
    grid-template-rows: auto;
    grid-template-columns: minmax(auto, 1fr) 2fr;
  }
`

export const Step = styled.div``

export const Card = styled.div`
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.04);
  padding: ${(props) => props.theme.space.xl};

  &:only-child,
  &:first-of-type {
    min-height: 30rem;
  }

  & > h1 {
    margin-top: 0;
  }

  & + & {
    margin-top: ${(props) => props.theme.space.xl};
  }
`

export const Pagination = styled.nav`
  margin-top: ${(props) => props.theme.space.md};
  display: flex;
  justify-content: space-between;
  width: 100%;

  @supports (display: grid) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "left right";
  }
`

export const PaginationLink = styled(Link)<{ align?: "left" | "right" }>`
  flex: 1;
  display: flex;
  flex-direction: column;

  text-decoration: none;

  grid-area: ${(props) => props.align};

  padding: ${(props) => props.theme.space.md};

  transition: opacity 0.2s ease-in-out;

  &:hover,
  &:focus {
    opacity: 0.75;
    outline: 0;
  }
`

export const ChecklistCard = styled(Box)`
  margin-top: ${(props) => props.theme.space.xl};
  padding: ${(props) => props.theme.space.xl};
  background-color: ${(props) => props.theme.colors.alabaster};

  & > *:first-of-type {
    margin-top: 0;
  }
`
