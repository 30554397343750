import Box, { BoxProps } from "@ui/Box"
import Heading from "@ui/Heading/Heading"
import Icon from "@ui/Icon/Icon"
import styled from "@emotion/styled"
import Text from "@ui/Text/Text"
import { graphql, Link, useStaticQuery } from "gatsby"
import { shade } from "polished"
import * as R from "ramda"
import React, { useState } from "react"
import { variant } from "styled-system"
import useModuleCompleted from "@components/useModuleCompleted"

const Container = styled(Box)<{ variant?: string }>`
  @media screen and (min-width: 45em) {
    position: sticky;
    top: 2rem;
  }

  --module-bg: ${(props) => props.theme.colors.rhino};
  color: #fff;

  ${(props) =>
    variant({
      variants: {
        preparation: {
          "--module-bg": props.theme.colors.tangerine,
          "--module-bg-dark": shade(0.15, props.theme.colors.tangerine),
        },
        vision: {
          "--module-bg": props.theme.colors.sienna,
          "--module-bg-dark": shade(0.15, props.theme.colors.sienna),
        },
        "context-analysis": {
          "--module-bg": props.theme.colors.blossom,
          "--module-bg-dark": shade(0.15, props.theme.colors.blossom),
        },
        "future-analysis": {
          "--module-bg": props.theme.colors.sky,
          "--module-bg-dark": shade(0.15, props.theme.colors.sky),
        },
        "strategic-plan": {
          "--module-bg": props.theme.colors.jade,
          "--module-bg-dark": shade(0.15, props.theme.colors.jade),
        },
        "operational-plan": {
          "--module-bg": props.theme.colors.acapulco,
          "--module-bg-dark": shade(0.15, props.theme.colors.acapulco),
        },
      },
    })}
`

const ModulesBlock = styled(Box)`
  background-color: ${(props) => props.theme.colors.rhino};
`

const AddonBlock = styled(Box)`
  background-color: var(--module-bg-dark);
`

const NavLink = styled(Link)`
  position: relative;
  display: block;
  align-items: center;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);

  & + & {
    margin-top: ${(props) => props.theme.space.lg};
  }

  transition: all 0.15s ease-in-out;

  &:before {
    content: "";
    position: absolute;
    width: ${(props) => props.theme.space.xl};
    height: ${(props) => props.theme.space["2xs"]};
    top: ${(props) => props.theme.space["2xs"]};
    right: 100%;
    margin-right: ${(props) => props.theme.space.md};
    background-color: transparent;
  }

  &[data-completed="true"] {
    color: rgba(255, 255, 255, 0.7);
    text-decoration: line-through;
  }

  :hover,
  :focus {
    color: rgba(255, 255, 255);
    outline: 0;
  }

  :focus {
    text-decoration: underline;
  }

  &[aria-current]:before {
    background-color: ${(props) => props.theme.colors.rhino};
  }
`

const query = graphql`
  {
    modules: allMdx(
      sort: { order: ASC, fields: [frontmatter___order] }
      filter: { frontmatter: { kind: { eq: "module" } } }
    ) {
      edges {
        node {
          frontmatter {
            kind
            id
            nbr
            title
          }
        }
      }
    }
    steps: allMdx(
      sort: { order: ASC, fields: [frontmatter___order] }
      filter: { frontmatter: { kind: { eq: "step" } } }
    ) {
      group(field: frontmatter___module) {
        fieldValue
        edges {
          node {
            frontmatter {
              kind
              id
              title
              module
              addon
              step
            }
          }
        }
      }
    }
  }
`

const AllModulesToggle = styled.button`
  border: 0;
  padding: 0 ${(props) => props.theme.space.xl};
  padding-top: 1px;
  height: ${(props) => props.theme.space["3xl"]};
  width: 100%;
  background-color: ${(props) => shade(0.15, props.theme.colors.rhino)};
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  font-size: ${(props) => props.theme.fontSizes.xs};
  text-align: left;
  line-height: 1;
  display: flex;
  align-items: center;
  cursor: pointer;

  transition: all 0.15s ease-in-out;

  &[aria-checked="true"] svg {
    transform: rotate(90deg);
  }

  &:hover,
  &:focus {
    background-color: ${(props) => shade(0.3, props.theme.colors.rhino)};
    outline: 0;
  }
`

type ModuleLink = {
  kind: "module"
  title: string
  id: string
  nbr: string
}

type StepLink = {
  kind: "step"
  module: string
  title: string
  step: string
  addon: string
  id: string
}

type GroupedLinks<T> = {
  [key: string]: T[]
}

interface Props extends BoxProps {
  module: string
}

const Nav = styled(Box.withComponent("nav"))`
  background-color: var(--module-bg);
`

const getNodes = R.pipe(
  R.pathOr([], ["edges"]),
  R.map(R.path(["node", "frontmatter"]))
)

const extract = R.pipe(
  R.pathOr([], ["group"]),
  R.map((group: any) => [group.fieldValue as string, getNodes(group)]) as any,
  R.fromPairs
)

const ModuleNav = ({ module, ...props }: Props) => {
  const { modules: gqlModules, steps: gqlSteps } = useStaticQuery(query)

  const [isModulesOpen, setModulesOpen] = useState(false)
  const modules = getNodes(gqlModules) as ModuleLink[]
  const stepLinks = extract(gqlSteps) as GroupedLinks<StepLink>

  const isModuleCompleted = useModuleCompleted()

  const currentModule = modules.find((item) => item.id === module)

  const rootModuleId = currentModule?.id

  const currentStepLinks = stepLinks[module].filter((step) => !step.addon)
  const addonLinks = stepLinks[module].filter((step) => step.addon)

  return (
    <Container variant={rootModuleId} {...props}>
      <AllModulesToggle
        role="switch"
        aria-checked={isModulesOpen}
        onClick={() => setModulesOpen(!isModulesOpen)}
      >
        All modules
        <Icon name="chevronRight" size="24px" />
      </AllModulesToggle>

      {isModulesOpen ? (
        <ModulesBlock p="xl">
          {modules.map(({ id, title, nbr }, index) => (
            <NavLink
              partiallyActive
              to={`/modules/${id}`}
              key={id}
              data-completed={isModuleCompleted(id)}
            >
              <Text fontWeight="bold">Module {nbr}</Text>
              <div>{title}</div>
            </NavLink>
          ))}
        </ModulesBlock>
      ) : (
        <>
          <Nav p="xl">
            <Heading mb="lg" fontWeight="bold">
              <div>Module {currentModule?.nbr}</div>
              {currentModule?.title}
            </Heading>
            {currentStepLinks.map(({ id, title, step, module }) => (
              <NavLink key={id} to={`/modules/${module}/${id}`}>
                {step && <div>Step {step}:</div>}
                {title}
              </NavLink>
            ))}
          </Nav>
          {addonLinks.length && (
            <AddonBlock mt="md" p="xl">
              {addonLinks.map(({ id, title, module }) => (
                <NavLink key={id} to={`/modules/${module}/${id}`}>
                  {title}
                </NavLink>
              ))}
            </AddonBlock>
          )}
        </>
      )}
    </Container>
  )
}

export default ModuleNav
