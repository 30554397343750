import styled from "@emotion/styled"

import { SpaceProps, space } from "styled-system"

const DividerLine = styled.hr<SpaceProps>`
  border: 1px solid ${({ theme }) => theme.colors.grey200};
  ${space};
`

export default DividerLine
