import React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import ModuleCheckbox from "./modulecheckbox"
import * as R from "ramda"
import { MDXProvider } from "@mdx-js/react"

type CheckboxData = {
  kind: "checkbox"
  id: string
  module: string
  step: string
  body: string
}

type ModuleCheckboxProps = {
  checkboxData: CheckboxData[]
  all?: boolean
  module: string
  step: string
}

const ModuleCheckboxlist = ({
  checkboxData,
  module,
  all = false,
  step,
}: ModuleCheckboxProps) => {
  const checkboxes = R.filter<CheckboxData>(
    (item: any) => item.module === module && (all || item.step === step),
    checkboxData
  )

  if (!checkboxes.length) {
    return null
  }

  return (
    <MDXProvider components={{ p: React.Fragment }}>
      {checkboxes.map((checkbox) => (
        <ModuleCheckbox
          key={checkbox.id}
          id={checkbox.id}
          module={checkbox.module}
          step={checkbox.step}
        >
          <MDXRenderer>{checkbox.body}</MDXRenderer>
        </ModuleCheckbox>
      ))}
    </MDXProvider>
  )
}

export default ModuleCheckboxlist
