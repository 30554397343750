import { useSelector } from "react-redux"
import { CompletedModulesSelector } from "@ducks/checkboxSlice"

const useModuleCompleted = () => {
  const completedModules = useSelector(CompletedModulesSelector)

  const isModuleCompleted = (moduleId: string) => {
    return completedModules[moduleId]
  }

  return isModuleCompleted
}

export default useModuleCompleted
